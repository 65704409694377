import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../Css/style.css";
import "../../Css/sidenav.css"
import Logo from "../../Images/logo-ct-dark.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useLocation } from "react-router-dom";
import {
  faTachometerAlt,
  faList,
  faUsers,
  faUser,
  faMoneyBills,
  faCamera,
  faMoneyBillTransfer,
  faFileText,
  faTrophy,
  faTimes,
  faCircleUser,
  faSignOutAlt,
  faPager,
  faLandmark,
  faSackDollar,
  faBriefcase,
  faDiceD6,
  faCommentDots,
  faBarsProgress,
  faMobileButton,
  faMoneyCheck,
  faWallet,
  faPercent,
  faHeadset,
  faPhotoFilm,
  faPerson,
  faRectangleAd
} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { Responsibilities } from "../../Configuration/Enums";
import { User_Context } from "../../Contexts/User";
import { API_Endpoint } from "../../Configuration/Settings";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { encryptData } from "../../utils/encryptData";

function Sidenav() {
  const [activeLink, setActiveLink] = useState("/");
  const [showSideNav, setShowSideNav] = useState(false);
  const [changePasswordPopup, setChangePasswordPopup] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [oldPassError, setOldPassError] = useState<boolean>(false);
  const [oldErrorHelperText, setOldErrorHelperText] = useState<string>("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassError, setNewPassError] = useState<boolean>(false);
  const [newErrorHelperText, setNewErrorHelperText] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPassError, setConfirmPassError] = useState<boolean>(false);
  const [confirmErrorHelperText, setConfirmErrorHelperText] = useState<string>("");
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const componentReference = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const { User, Set_User } = useContext(User_Context);
  const [chatUrl, setChatUrl] = useState('')

  useEffect(() => {
    const path = location.pathname;
    if (path === "/dashboard") {
      setActiveLink("home");
    } else if (path === "/welcome") {
      setActiveLink("welcome");
    } else if (path === "/tables") {
      setActiveLink("tables");
    } else if (path === "/users") {
      setActiveLink("users");
    } else if (path === "/payment-gateway") {
      setActiveLink("paymentgateway");
    } else if (path === "/pay-g-mid") {
      setActiveLink("paygmid");
    } else if (path === "/payout-accounts") {
      setActiveLink("payout");
    } else if (path === "/upi-lists") {
      setActiveLink("upilists");
    } else if (path === "/banners") {
      setActiveLink("banners");
    } else if (path === "/users-kyc") {
      setActiveLink("userskyc");
    } else if (path === "/deposit") {
      setActiveLink("deposit");
    } else if (path === "/withdrawal") {
      setActiveLink("withdrawal");
    } else if (path === "/fund-request") {
      setActiveLink("fund-request");
    } else if (path === "/bonus-wallet-fund-request") {
      setActiveLink("bonus-wallet-fund-request");
    } else if (path === "/casino-lists") {
      setActiveLink("casinolists");
    } else if (path === "/billing") {
      setActiveLink("billing");
    } else if (path === "/listing") {
      setActiveLink("listing");
    } else if (path === "/profile") {
      setActiveLink("profile");
    } else if (path === "/signin") {
      setActiveLink("signin");
    } else if (path === "/roles-responsibilities") {
      setActiveLink("roles-responsibilities");
    } else if (path === "/signup") {
      setActiveLink("signup");
    } else if (path === "/feedback") {
      setActiveLink("feedbacklist");
    } else if (path === "/completed_feedback") {
      setActiveLink("completedfeedbacklist");
    } else if (path === "/profit-and-loss") {
      setActiveLink("profitandloss");
    } else if (path === "/mobile-app") {
      setActiveLink("mobileapp");
    } else if (path === "/utr-provider") {
      setActiveLink("utrprovider");
    } else if (path === "/bonus-wallet") {
      setActiveLink("bonuswallet");
    } else if (path === "/percentage") {
      setActiveLink("percentage");
    } else if (path === "/social-media") {
      setActiveLink("socialmedia");
    } else if (path === "/customer-allotment") {
      setActiveLink("customerallotment");
    } else if (path === "/my-customer") {
      setActiveLink("mycustomer");
    } else if (path === "promotions") {
      setActiveLink("promotions");
    }
  }, [location.pathname]);
  const handleClose = () => {
    setOpen(false);
  };



  const handleClosePassword = () => {
    setChangePasswordPopup(false);
    setOldPassError(false)
    setOldPassword("")
    setOldErrorHelperText("")
    setNewPassError(false)
    setNewPassword("")
    setNewErrorHelperText("")
    setConfirmPassword("")
    setConfirmPassError(false)
    setConfirmErrorHelperText("")
  };

  const handleOpenChangePassword = (event: any) => {
    setOpen(false);
    setChangePasswordPopup(true);
    event.preventDefault();
  };

  const handleOldPasswordChange = (event: any) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event: any) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPassword = (event: any) => {
    setConfirmPassword(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    Set_User({ token: "", data: { _id: '', name: '', mobile: '', email: '', balance: 0, profitLoss: 0, createdOn: '', updatedOn: '', Role_ID: '', Responsibilities: [], DP_ID: '', chatName: '' } });
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      componentReference.current &&
      !componentReference.current.contains(event.target as Node)
    ) {
      setShowSideNav(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    let url = User.data.Role_ID == "64f710d9a2ab78980020c5fb" ? 'https://chatapp.space/' : `https://chatapp.space/chat?from_id=${User.data.DP_ID}&from_user=${User.data.chatName}%20Sharma&url_type=team_user&url_app=fairbets`
    setChatUrl(url)
  }, [])

  const handleChangePassword = () => {
    if (!oldPassword) {
      setOldPassError(true);
      setOldErrorHelperText("Enter old password");
    } else if (!newPassword) {
      setNewPassError(true);
      setNewErrorHelperText("Enter new password");
    } else if (!confirmPassword) {
      setConfirmPassError(true);
      setConfirmErrorHelperText("Enter confirm password");
    } else {
      const token = localStorage.getItem("token");
      axios
        .post(
          `${API_Endpoint}/SubAdmin/update-password`,
          {
            token: encryptData({
              userId: User.data._id,
              password: oldPassword,
              newPassword: newPassword
            })
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setChangePasswordPopup(false)
          toast("Password updated successfully");
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }

  return (
    <>
      <div className="g-sidenav-show  bg-gray-100">
        <ToastContainer autoClose={2000} position="top-center" />
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
          <nav
            className="navbar navbar-main navbar-expand-lg"
            id="navbarBlur"
            navbar-scroll="true"
          >
            <div className="container-fluid">
              <div className="collapse navbar-collapse">
                <ul className="navbar-nav">
                  <li className="nav-item d-xl-none d-flex align-items-center">
                    <a className="nav-link text-body p-0">
                      <div
                        className="sidenav-toggler-inner"
                        onClick={() => setShowSideNav(true)}
                      >
                        <i className="sidenav-toggler-line" />
                        <i className="sidenav-toggler-line" />
                        <i className="sidenav-toggler-line" />
                      </div>
                    </a>
                  </li>
                </ul>
                <div className="ms-md-auto pe-md-3 d-flex align-items-center" />
                <ul className="navbar-nav justify-content-end me-3 ">
                  <li className="nav-item prof d-flex align-items-center">
                    <FontAwesomeIcon
                      onClick={handleOpen}
                      className="fa fa-user me-sm-1 icon-dash-user"
                      icon={faUser}
                    />
                  </li>
                </ul>
                {localStorage.getItem("token") ? (
                  <ul className="navbar-nav justify-content-end">
                    <li className="nav-item signot d-flex align-items-center">
                      <FontAwesomeIcon
                        onClick={handleLogout}
                        className="fa fa-user me-sm-1 icon-dash-user"
                        icon={faSignOutAlt}
                      />
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>
              <Dialog
                className="dailog-sidenav"
                open={open}
                onClose={handleClose}
              >
                <DialogContent>
                  <form>
                    <div>
                      <div className="d-flex">
                        <label className="name-label">Name:</label>
                        <label className="value-label">{User.data.name}</label>
                      </div>
                      <div className="d-flex">
                        <label className="name-label">Email:</label>
                        <label className="value-label">{User.data.email}</label>
                      </div>
                      <div className="d-flex">
                        <label className="name-label">Mobile:</label>
                        <label className="value-label">
                          {User.data.mobile}
                        </label>
                      </div>
                    </div>

                    <DialogActions className="mt-2">
                      <Button
                        className="btn-popup1"
                        variant="contained"
                        onClick={handleClose}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-popup1 min-pop"
                        variant="contained"
                        type="submit"
                        color="primary"
                        onClick={(e) => handleOpenChangePassword(e)}
                      >
                        Update Password
                      </Button>
                    </DialogActions>
                  </form>
                </DialogContent>
              </Dialog>
            </div>

            <div>
              <Dialog open={changePasswordPopup} onClose={handleClosePassword}>
                {/* <DialogTitle>Payment Form</DialogTitle> --------Ignore this-------- */}
                <DialogContent>
                  <form>
                    <div>
                      <TextField
                        onChange={handleOldPasswordChange}
                        value={oldPassword}
                        error={oldPassError}
                        helperText={oldErrorHelperText}
                        id="outlined-basic"
                        type="password"
                        fullWidth
                        label="Old Password"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          style: {
                            fontFamily: "Roboto",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>

                    <div>
                      <TextField
                        className="mt-3"
                        onChange={handleNewPasswordChange}
                        value={newPassword}
                        error={newPassError}
                        helperText={newErrorHelperText}
                        id="outlined-basic"
                        type="password"
                        fullWidth
                        label="New Password"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          style: {
                            fontFamily: "Roboto",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        className="mt-3"
                        onChange={handleConfirmPassword}
                        value={confirmPassword}
                        error={confirmPassError}
                        helperText={confirmErrorHelperText}
                        id="outlined-basic"
                        type="password"
                        fullWidth
                        label="Confirm New Password"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          style: {
                            fontFamily: "Roboto",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>
                    {newPassword !== confirmPassword ? <p className="passmatch">New Password and Confirm Password Does Not Match</p> : ""}
                    <DialogActions className="mt-2">
                      <Button
                        className="btn-popup1"
                        variant="contained"
                        onClick={handleClosePassword}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-popup1"
                        variant="contained"
                        color="primary"
                        onClick={handleChangePassword}
                      >
                        Update
                      </Button>
                    </DialogActions>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
          </nav>
          {showSideNav === true ? (
            <aside
              ref={componentReference}
              className="sidenav navbar-vertical navbar-expand-xs  fixed-start"
            >
              <div className="sidenav-header">
                <i
                  className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                  aria-hidden="true"
                  id="iconSidenav"
                />
                <a className="navbar-brand m-0 cancel-div">
                  <img
                    src={Logo}
                    className="navbar-brand-img h-100 image-navbar"
                    alt="main_logo"
                  />
                  <FontAwesomeIcon
                    onClick={() => setShowSideNav(false)}
                    className="fa fa-bell cursor-pointer cancel-btn"
                    icon={faTimes}
                  />
                </a>
              </div>
              <hr className="horizontal dark" />
              <div
                className="collapse navbar-collapse w-auto"
                id="sidenav-collapse-main"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink
                      to="/welcome"
                      className={`nav-link ${activeLink === "welcome" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-tachometer icon-home"
                          icon={faPager}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Welcome Page{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Dashboard
                  ) && (
                      <li hidden={User.data.Role_ID!=="64f710d9a2ab78980020c5fb"} className="nav-item">
                        <NavLink
                          to="/dashboard"
                          className={`nav-link ${activeLink === "home" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-tachometer icon-home"
                              icon={faTachometerAlt}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Dashboard{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/promotions"
                      className={`nav-link ${activeLink === "promotions" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-tachometer icon-home"
                          icon={faRectangleAd}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Promotions{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/percentage"
                      className={`nav-link ${activeLink === "percentage" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-tachometer icon-home"
                          icon={faPercent}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Percentage{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Users
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/users"
                          className={`nav-link ${activeLink === "users" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faUsers}
                            />
                          </div>
                          <span className="nav-link-text nav-sign"> Users </span>
                        </NavLink>
                      </li>
                    )}
                  {/*User.data.Responsibilities.includes(
                    Responsibilities.View_Payment_Gateways
                  ) && (
                    <li className="nav-item">
                      <NavLink
                        to="/payment-gateway"
                        className={`nav-link ${
                          activeLink === "paymentgateway" ? "active" : ""
                        }`}
                      >
                        <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                          <FontAwesomeIcon
                            className="fa fa-users icon-home"
                            icon={faMoneyBills}
                          />
                        </div>
                        <span className="nav-link-text nav-sign">
                          {" "}
                          Payment Gateway{" "}
                        </span>
                      </NavLink>
                    </li>
                  )*/}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_PayIn_Accounts
                  ) && (
                      <li hidden={User.data._id==="6578386051e82e7aff9bdc09"} className="nav-item">
                        <NavLink
                          to="/pay-g-mid"
                          className={`nav-link ${activeLink === "paygmid" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-list icon-home"
                              icon={faLandmark}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Deposit Providers{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_PayOut_Accounts
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/payout-accounts"
                          className={`nav-link ${activeLink === "payout" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-list icon-home"
                              icon={faBriefcase}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Withdrawal Providers{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/utr-provider"
                      className={`nav-link ${activeLink === "utrprovider" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-list icon-home"
                          icon={faMoneyCheck}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Utr Providers{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_UPIs
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/upi-lists"
                          className={`nav-link ${activeLink === "upilists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-list icon-home"
                              icon={faList}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            AB UPIs{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Banners
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/banners"
                          className={`nav-link ${activeLink === "banners" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-list icon-home"
                              icon={faCamera}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Banners List{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_KYCs
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/users-kyc"
                          className={`nav-link ${activeLink === "userskyc" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faFileText}
                            />
                          </div>
                          <span className="nav-link-text nav-sign"> KYC </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Deposits
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/deposit"
                          className={`nav-link ${activeLink === "deposit" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faTrophy}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Deposit{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.Unique_Deposit_Pending_User
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/unique_deposit_pending"
                          className={`nav-link ${activeLink === "feedbacklists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faUsers}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Unique Deposit Pending User{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}

                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Withdrawals
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/withdrawal"
                          className={`nav-link ${activeLink === "withdrawal" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faMoneyBillTransfer}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Withdrawal{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/bonus-wallet"
                      className={`nav-link ${activeLink === "bonuswallet" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-file-text icon-home"
                          icon={faWallet}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Bonus Wallet Requests{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.Fund_Request
                  ) && (
                      <li hidden={User.data.Role_ID!=="64f710d9a2ab78980020c5fb"} className="nav-item">
                        <NavLink
                          to="/fund-request"
                          className={`nav-link ${activeLink === "fund-request" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faSackDollar}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Fund Requests{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li hidden={User.data.Role_ID!=="64f710d9a2ab78980020c5fb"} className="nav-item">
                    <NavLink
                      to="/fund-request-bonus-wallet"
                      className={`nav-link ${activeLink === "fund-request-bonus-wallet" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-file-text icon-home"
                          icon={faSackDollar}
                        />
                      </div>
                      <span style={{ whiteSpace: "pre-line" }} className="nav-link-text nav-sign">
                        Bonus Wallet {"\n"} Fund Requests
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Games
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/casino-lists"
                          className={`nav-link ${activeLink === "casinolists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faDiceD6}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Casino Games{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/social-media"
                      className={`nav-link ${activeLink === "socialmedia" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-file-text icon-home"
                          icon={faPhotoFilm}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Social Media{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Feedback
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/feedback"
                          className={`nav-link ${activeLink === "feedbacklist" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faCommentDots}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                           Pending Feedback{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    <li className="nav-item">
                        <NavLink
                          to="/completed_feedback"
                          className={`nav-link ${activeLink === "completedfeedbacklist" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faCommentDots}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Completed Feedback{" "}
                          </span>
                        </NavLink>
                      </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Roles_and_Responsibilities
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/roles-responsibilities"
                          className={`nav-link ${activeLink === "roles-responsibilities"
                            ? "active"
                            : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faCircleUser}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Roles & Responsibilities{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Profit_and_Loss
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/profit-and-loss"
                          className={`nav-link ${activeLink === "profitandloss" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faBarsProgress}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Profit & Loss{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/mobile-app"
                      className={`nav-link ${activeLink === "mobileapp" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-file-text icon-home"
                          icon={faMobileButton}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Mobile App{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.Chat_Support
                  ) && (
                      <li className="nav-item">
                        <a
                          onClick={() => {
                            const url = chatUrl;
                            window.open(url, "_blank");
                          }}
                          className={`nav-link ${activeLink === "chatsupport" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faHeadset}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Chat Support{" "}
                          </span>
                        </a>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.Admin_Chat_Support
                  ) && (
                      <li className="nav-item">
                        <a
                          onClick={() => {
                            const url = "https://chatapp.space/";
                            window.open(url, "_blank");
                          }}
                          className={`nav-link ${activeLink === "adminchatsupport" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faHeadset}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Chat Support{" "}
                          </span>
                        </a>
                      </li>
                    )}

                  {User.data.Responsibilities.includes(
                    Responsibilities.Non_Performing_User
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/non_performing_user"
                          className={`nav-link ${activeLink === "feedbacklists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faUsers}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Non Performing User{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {/* {User.data.Responsibilities.includes(
                    Responsibilities.Unique_Deposit_Pending_User
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/unique_deposit_pending"
                          className={`nav-link ${activeLink === "feedbacklists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faUsers}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Unique Deposit Pending User{" "}
                          </span>
                        </NavLink>
                      </li>
                    )} */}
                  {User.data.Responsibilities.includes(
                    Responsibilities.customer_allotment
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/customer-allotment"
                          className={`nav-link ${activeLink === "customerallotment" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faPerson}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Customer Allotment{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.my_customers
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/my-customer"
                          className={`nav-link ${activeLink === "mycustomer" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faPerson}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            My Customers {" "}
                          </span>
                        </NavLink>
                      </li>)}
                </ul>
              </div>
            </aside>
          ) : (
            <aside className="sidenav navbar-vertical navbar-expand-xs  fixed-start web-side-nav">
              <div className="sidenav-header">
                <i
                  className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                  aria-hidden="true"
                  id="iconSidenav"
                />
                <a className="navbar-brand m-0 ">
                  <img
                    src={Logo}
                    className="navbar-brand-img h-100"
                    alt="main_logo"
                  />
                </a>
              </div>
              <hr className="horizontal dark" />
              <div
                className="collapse navbar-collapse w-auto"
                id="sidenav-collapse-main"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink
                      to="/welcome"
                      className={`nav-link ${activeLink === "welcome" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-tachometer icon-home"
                          icon={faPager}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Welcome Page{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Dashboard
                  ) && (
                      <li hidden={User.data.Role_ID!=="64f710d9a2ab78980020c5fb"} className="nav-item">
                        <NavLink
                          to="/dashboard"
                          className={`nav-link ${activeLink === "home" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-tachometer icon-home"
                              icon={faTachometerAlt}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Dashboard{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    <li className="nav-item">
                    <NavLink
                      to="/promotions"
                      className={`nav-link ${activeLink === "promotions" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-tachometer icon-home"
                          icon={faRectangleAd}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Promotions{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/percentage"
                      className={`nav-link ${activeLink === "percentage" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-tachometer icon-home"
                          icon={faPercent}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Percentage{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Users
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/users"
                          className={`nav-link ${activeLink === "users" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faUsers}
                            />
                          </div>
                          <span className="nav-link-text nav-sign"> Users </span>
                        </NavLink>
                      </li>
                    )}
                  {/*User.data.Responsibilities.includes(
                    Responsibilities.View_Payment_Gateways
                  ) && (
                    <li className="nav-item">
                      <NavLink
                        to="/payment-gateway"
                        className={`nav-link ${
                          activeLink === "paymentgateway" ? "active" : ""
                        }`}
                      >
                        <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                          <FontAwesomeIcon
                            className="fa fa-users icon-home"
                            icon={faMoneyBills}
                          />
                        </div>
                        <span className="nav-link-text nav-sign">
                          {" "}
                          Payment Gateway{" "}
                        </span>
                      </NavLink>
                    </li>
                  )*/}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_PayIn_Accounts
                  ) && (
                      <li hidden={User.data._id==="6578386051e82e7aff9bdc09"} className="nav-item">
                        <NavLink
                          to="/pay-g-mid"
                          className={`nav-link ${activeLink === "paygmid" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-list icon-home"
                              icon={faLandmark}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Deposit Providers{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_PayOut_Accounts
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/payout-accounts"
                          className={`nav-link ${activeLink === "payout" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-list icon-home"
                              icon={faBriefcase}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Withdrawal Providers{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/utr-provider"
                      className={`nav-link ${activeLink === "utrprovider" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-list icon-home"
                          icon={faMoneyCheck}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Utr Providers{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_UPIs
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/upi-lists"
                          className={`nav-link ${activeLink === "upilists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-list icon-home"
                              icon={faList}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            AB UPIs{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Banners
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/banners"
                          className={`nav-link ${activeLink === "banners" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-list icon-home"
                              icon={faCamera}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Banners List{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_KYCs
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/users-kyc"
                          className={`nav-link ${activeLink === "userskyc" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faFileText}
                            />
                          </div>
                          <span className="nav-link-text nav-sign"> KYC </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Deposits
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/deposit"
                          className={`nav-link ${activeLink === "deposit" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faTrophy}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Deposit{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.Unique_Deposit_Pending_User
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/unique_deposit_pending"
                          className={`nav-link ${activeLink === "feedbacklists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faUsers}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Unique Deposit Pending User{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Withdrawals
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/withdrawal"
                          className={`nav-link ${activeLink === "withdrawal" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faMoneyBillTransfer}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Withdrawal{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/bonus-wallet"
                      className={`nav-link ${activeLink === "bonuswallet" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-file-text icon-home"
                          icon={faWallet}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Bonus Wallet Requests{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.Fund_Request
                  ) && (
                      <li hidden={User.data.Role_ID!=="64f710d9a2ab78980020c5fb"}  className="nav-item">
                        <NavLink
                          to="/fund-request"
                          className={`nav-link ${activeLink === "fund-request" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faSackDollar}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Fund Requests{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/fund-request-bonus-wallet"
                      className={`nav-link ${activeLink === "fund-request-bonus-wallet" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-file-text icon-home"
                          icon={faSackDollar}
                        />
                      </div>
                      <span style={{ whiteSpace: "pre-line" }} className="nav-link-text nav-sign">
                        Bonus Wallet {"\n"} Fund Requests
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Games
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/casino-lists"
                          className={`nav-link ${activeLink === "casinolists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faDiceD6}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Casino Games{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/social-media"
                      className={`nav-link ${activeLink === "socialmedia" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-file-text icon-home"
                          icon={faPhotoFilm}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Social Media{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Feedback
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/feedback"
                          className={`nav-link ${activeLink === "feedbacklists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faCommentDots}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                           Pending Feedback{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                   
                      <li className="nav-item">
                        <NavLink
                          to="/completed_feedback"
                          className={`nav-link ${activeLink === "completedfeedbacklist" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faCommentDots}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Completed Feedback{" "}
                          </span>
                        </NavLink>
                      </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Roles_and_Responsibilities
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/roles-responsibilities"
                          className={`nav-link ${activeLink === "roles-responsibilities"
                            ? "active"
                            : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faCircleUser}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Roles & Responsibilities{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.View_Profit_and_Loss
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/profit-and-loss"
                          className={`nav-link ${activeLink === "profitandloss" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faBarsProgress}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Profit & Loss{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  <li className="nav-item">
                    <NavLink
                      to="/mobile-app"
                      className={`nav-link ${activeLink === "mobileapp" ? "active" : ""
                        }`}
                    >
                      <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          className="fa fa-file-text icon-home"
                          icon={faMobileButton}
                        />
                      </div>
                      <span className="nav-link-text nav-sign">
                        {" "}
                        Mobile App{" "}
                      </span>
                    </NavLink>
                  </li>
                  {User.data.Responsibilities.includes(
                    Responsibilities.Chat_Support
                  ) && (
                      <li className="nav-item">
                        <a
                          onClick={() => {
                            const url = chatUrl;
                            window.open(url, "_blank");
                          }}
                          className={`nav-link ${activeLink === "chatsupport" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faHeadset}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Chat Support{" "}
                          </span>
                        </a>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.Admin_Chat_Support
                  ) && (
                      <li className="nav-item">
                        <a
                          onClick={() => {
                            const url = "https://chatapp.space/";
                            window.open(url, "_blank");
                          }}
                          className={`nav-link ${activeLink === "adminchatsupport" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-file-text icon-home"
                              icon={faHeadset}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Chat Support{" "}
                          </span>
                        </a>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.Non_Performing_User
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/non_performing_user"
                          className={`nav-link ${activeLink === "feedbacklists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faUsers}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Non Performing User{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {/* {User.data.Responsibilities.includes(
                    Responsibilities.Unique_Deposit_Pending_User
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/unique_deposit_pending"
                          className={`nav-link ${activeLink === "feedbacklists" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faUsers}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Unique Deposit Pending User{" "}
                          </span>
                        </NavLink>
                      </li>
                    )
                    } */}
                  {User.data.Responsibilities.includes(
                    Responsibilities.customer_allotment
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/customer-allotment"
                          className={`nav-link ${activeLink === "customerallotment" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faPerson}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            Customer Allotment{" "}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {User.data.Responsibilities.includes(
                    Responsibilities.my_customers
                  ) && (
                      <li className="nav-item">
                        <NavLink
                          to="/my-customer"
                          className={`nav-link ${activeLink === "mycustomer" ? "active" : ""
                            }`}
                        >
                          <div className="icon icon-shape icon-sm  bg-white text-center me-2 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              className="fa fa-users icon-home"
                              icon={faPerson}
                            />
                          </div>
                          <span className="nav-link-text nav-sign">
                            {" "}
                            My Customers {" "}
                          </span>
                        </NavLink>
                      </li>)}
                </ul>
              </div>
            </aside>
          )}
        </main>
      </div>
    </>
  );
}

export default Sidenav;