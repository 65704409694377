import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Pages/Panel/Views/Dashboard/Dashboard";
import User_List from "./Pages/Panel/Views/Users/Users";
import PayGmid from "./Pages/Panel/Views/Providers/Deposit_Withdraw_Providers";
import Banners from "./Pages/Panel/Views/Banners/Banners";
import Userkyc from "./Pages/Panel/Views/KYC/KYC";
import UpiList from "./Pages/Panel/Views/UPI/UPI";
import Deposit from "./Pages/Panel/Views/Deposit/Deposit";
import CasinoList from "./Pages/Panel/Views/Casino_Games/Casino_Games";
import ProfitandLoss from "./Pages/Panel/Views/Profit_and_Loss/Profit_and_Loss";
import Withdraw from "./Pages/Panel/Views/Withdrawal/Withdrawal";
import Feedback from "./Pages/Panel/Views/Feedback/Feedback";
import RolesResponsibilities from "./Pages/Panel/Views/Roles_and_Responsibilities/Roles_and_Responsibilities";
import MobileApp from "./Pages/Panel/Views/MobileApp/MobileApp";
import "./App.css";
import { Responsibilities } from "./Configuration/Enums";
import User_Provider from "./Contexts/User";
import Private_Route from "./Routes/Private_Route";
import WelcomePage from "./Pages/Panel/Views/Welcome/Welcome";
import User_Report from "./Pages/UserReport/User_Report";
import Authentication from "./Pages/Authentication/Authentication";
import Not_Found from "./Pages/Not_Found/Not_Found";
import FundRequest from "./Components/FundRequests/FundRequest";
import FundRequestTable from "./Components/FundRequests/FundRequestTable";
import UtrProvider from "./Pages/Panel/Views/Providers/UtrProvider";
import BonusWallet from "./Pages/Panel/Views/BonusWallet/BonusWallet";
import BonusWalletHistory from "./Pages/Panel/Views/BonusWallet/BonusWalletHistory";
import Percentage from "./Components/Percentage/Percentage";
import FundRequestCoinTable from "./Components/FundRequests/FundRequestCoinTable";
import FundRequestBonusWallet from "./Pages/Panel/Views/FundRequestBonusWallet/FundRequestBonusWallet";
import FundRequestBonusWalletTable from "./Pages/Panel/Views/FundRequestBonusWallet/FundRequestBonusWalletTable";
import NonPerformingUser from "./Components/NonPerformingUser/NonPerformingUser"
import UniquePendingDepositUser from "./Components/UniquePendingDeposit/UniquePendingDepositUser";
import SocialMedia from "./Pages/Panel/Views/Social_Media/SocialMedia";
import UserDepositCoinTable from "./Components/FundRequests/UserDepositCoinTable";
import BonusWalletReferralEarning from "./Pages/Panel/Views/BonusWallet/BonusWalletReferralEarning";
import GlobalUsersList from "./Pages/Panel/Views/Dashboard/GlobalUsersList";
import CustomerAllotment from "./Components/CustomerAllotment/CustomerAllotment";
import AllotedCustomer from "./Components/CustomerAllotment/AllotedCustomer";
import AddCustomers from "./Components/CustomerAllotment/AddCustomers";
import RemoveCustomer from "./Components/CustomerAllotment/RemoveCustomer";
import MyCustomers from "./Components/CustomerAllotment/MyCustomers";
import UserExposure from "./Pages/Panel/Views/BonusWallet/UserExposure";
import CompletedFeedback from "./Pages/Panel/Views/Feedback/CompletedFeedback";
import CallerReport from "./Components/CustomerAllotment/CallerReport";
import Promotions from "./Pages/Panel/Views/Promotions/Promotions";

const Application = () =>
	<User_Provider>
		<BrowserRouter>
			<Routes>
				<Route key='Authentication_Page_Key' path="/" element={<Authentication />} />
				<Route key='Welcome_Page_Key' path="/welcome" element={<Private_Route Redirection_Path='/not-found'><WelcomePage /></Private_Route>} />
				<Route key='Promotion_Page_Key' path="/promotions" element={<Private_Route Redirection_Path='/not-found'><Promotions /></Private_Route>} />
				<Route key='Social_Media_key' path="/social-media" element={<Private_Route Redirection_Path='/not-found'><SocialMedia /></Private_Route>} />
				<Route key='Percentage_Page' path="/percentage" element={<Private_Route Redirection_Path='/not-found'><Percentage /></Private_Route>} />
				<Route key='Bonus_Wallet_Page' path="/bonus-wallet" element={<Private_Route Redirection_Path='/not-found'><BonusWallet /></Private_Route>} />
				<Route key='Bonus_Wallet_History_Page' path="/bonus-wallet-history/:userId" element={<Private_Route Redirection_Path='/not-found'><BonusWalletHistory /></Private_Route>} />
				<Route key='Fund_Request' path="/fund-request" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.Fund_Request}><FundRequest /></Private_Route>} />
				<Route key='Fund_Request_Bonus_Wallet' path="/fund-request-bonus-wallet" element={<Private_Route Redirection_Path='/not-found'><FundRequestBonusWallet /></Private_Route>} />

				<Route key='Fund_Request_Bonus_Wallet_Table' path="/fund-request-bonus-wallet-table" element={<Private_Route Redirection_Path='/not-found'><FundRequestBonusWalletTable /></Private_Route>} />
				<Route key='Referral_Earning_Bonus_Wallet_Table' path="/bonus-wallet-referral-earning" element={<Private_Route Redirection_Path='/not-found'><BonusWalletReferralEarning /></Private_Route>} />
				<Route key='User_Exposure' path="/user_exposure" element={<Private_Route Redirection_Path='/not-found'><UserExposure /></Private_Route>} />

				<Route key='Dashboard_Page_Key' path="/dashboard" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Dashboard}><Home /></Private_Route>} />
				<Route key='Users_Page_Key' path="/users" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Users}><User_List /></Private_Route>} />
				{/*<Route key='Payment_Gateways_Page_Key' path="/payment-gateway" element={<Private_Route Responsibility={Responsibilities.View_Payment_Gateways}><PaymentGateway /></Private_Route>} />*/}
				<Route key='Pay_In_Page_Key' path="/pay-g-mid" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_PayIn_Accounts}><PayGmid /></Private_Route>} />
				<Route key='Utr_Page_Key' path="/utr-provider" element={<Private_Route Redirection_Path='/not-found'><UtrProvider /></Private_Route>} />
				<Route key='Pay_Out_Page_Key' path="/payout-accounts" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_PayOut_Accounts}><PayGmid /></Private_Route>} />
				<Route key='Banners_Page_Key' path="/banners" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Banners}><Banners /></Private_Route>} />
				<Route key='KYC_Page_Key' path="/users-kyc" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_KYCs}><Userkyc /></Private_Route>} />
				<Route key='Deposit_Page_Key' path="/deposit" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Deposits}><Deposit /></Private_Route>} />
				<Route key='Withdrawal_Page_Key' path="/withdrawal" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Withdrawals}><Withdraw /></Private_Route>} />
				<Route key='UPI_Page_Key' path="/upi-lists" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_UPIs}><UpiList /></Private_Route>} />
				<Route key='Game_Page_Key' path="/casino-lists" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Games}><CasinoList /></Private_Route>} />
				<Route key='Profit_and_Loss_Page_Key' path="/profit-and-loss" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Profit_and_Loss}><ProfitandLoss /></Private_Route>} />
				<Route key='User_Report_Page_Key' path="/user-report/:User_ID/:userName" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Profit_and_Loss}><User_Report /></Private_Route>} />
				<Route key='Fund_Request_Page' path="/fundreq-table" element={<Private_Route Redirection_Path='/not-found'><FundRequestTable /></Private_Route>} />
				<Route key='Fund_Request_Coin_Page' path="/fundreq-coin" element={<Private_Route Redirection_Path='/not-found'><FundRequestCoinTable /></Private_Route>} />
				<Route key='User_Deposit_Coin_Page' path="/user-deposit" element={<Private_Route Redirection_Path='/not-found'><UserDepositCoinTable /></Private_Route>} />
				<Route key='Feedback_Page_Key' path="/feedback" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Feedback}><Feedback /></Private_Route>} />
				<Route key='Completed_Feedback_Page_Key' path="/completed_feedback" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Feedback}><CompletedFeedback /></Private_Route>} />
				<Route key='Non_Performing-user' path="/non_performing_user" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.Non_Performing_User}><NonPerformingUser /></Private_Route>} />
				<Route key='Unique_deposit_pending' path="/unique_deposit_pending" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.Unique_Deposit_Pending_User}><UniquePendingDepositUser /></Private_Route>} />
				<Route key='Roles_and_Responsibilities_Page_Key' path="/roles-responsibilities" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Roles_and_Responsibilities}><RolesResponsibilities /></Private_Route>} />
				<Route key='Mobile_App_Page_Key' path="/mobile-app" element={<Private_Route Redirection_Path='/not-found' ><MobileApp /></Private_Route>} />
				<Route key='User_Global_Page_Key' path="/users-global-list" element={<Private_Route Redirection_Path='/not-found' ><GlobalUsersList /></Private_Route>} />
				<Route key='Customer_Allotment_Page_Key' path="/customer-allotment" element={<Private_Route Redirection_Path='/not-found' ><CustomerAllotment /></Private_Route>} />
				<Route key='Customer_Allotted_Page_Key' path="/customer-allotted" element={<Private_Route Redirection_Path='/not-found' ><AllotedCustomer /></Private_Route>} />
				<Route key='Add_Customer_Page_Key' path="/add-customer" element={<Private_Route Redirection_Path='/not-found' ><AddCustomers /></Private_Route>} />
				<Route key='Remove_Customer_Page_Key' path="/remove-customer" element={<Private_Route Redirection_Path='/not-found' ><RemoveCustomer /></Private_Route>} />
				<Route key='My_Customer_Page_Key' path="/my-customer" element={<Private_Route Redirection_Path='/not-found' ><MyCustomers /></Private_Route>} />
				
				<Route key='Users_Page_Key' path="/caller-report" element={<Private_Route Redirection_Path='/not-found' Responsibility={Responsibilities.View_Users}><CallerReport/></Private_Route>} />
				<Route key='Miscellaneous_Page_Key' path="*" element={<Not_Found />} />
			</Routes>
		</BrowserRouter>
	</User_Provider>

export default Application;
