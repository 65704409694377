import React, { useState, useEffect } from 'react'
import Sidenav from '../../../../Components/SideNavigation/SideNavigation'
import Breadcrumbs from '../../../../Components/Breadcrumbs/Breadcrumbs'
import StatCard from '../../../../Components/Statistics/StatCard'
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { API_Endpoint } from '../../../../Configuration/Settings';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

interface Promotions {
    name: string
    count: number
}

const Promotions = () => {
    const [loading, setLoading] = useState(false)
    const [referEarn, setReferEarn] = useState<Promotions[]>([]);

    const getCount = () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        let config = {
            method: "post",
            url: `${API_Endpoint}/promotion/count`,
            headers: { Authorization: `Bearer ${token}`, },
            data: { token: encryptData({}) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                console.log(response.data.data.payload);
                setReferEarn(response.data.data.payload)
                setLoading(false)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false);
            });
    };

    useEffect(() => {
        getCount()
    }, [])

    return (
        <>
            <div className="g-sidenav-show">
                <Sidenav />
                <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                    <Breadcrumbs tab={"Promotions Count"} />
                    <div className="container-fluid py-1">
                        <div className="row">
                            {referEarn.map((item: Promotions) => (
                                <StatCard
                                    title={item.name}
                                    dataVal={true}
                                    icon={faUserCircle}
                                    value={item.count}
                                    className={"fa fa-shopping-cart mid-icon"}
                                />
                            ))}
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Promotions