import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./dwcard.css"

const StatCard = ({ title, value, icon, className, data, dataVal } : any ) => {
  
  return (
    <div onClick={data} style={{cursor:"pointer"}} className={`col-xl-4 col-sm-6 mb-xl-0 mb-4  mt-3 ${className}`}>
      <div className="card" style={{background: dataVal === true ? "#f3f3f3" : "", boxShadow: dataVal === true ? "none" : ""}}>
        <div className="card-body p-3">
          <div className="row">
            <div className="col-9">
              <div>
                <p className="title-name mb-0 text-capitalize font-weight-bold">
                  {title}
                </p>
                <h5 className="font-weight-bolder mb-0">{value}</h5>
              </div>
            </div>
            <div className="col-3 text-end">
              <div className="icon inn icon-shape bg-gradient-primary shadow text-center border-radius-md">
                <FontAwesomeIcon className={className} icon={icon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCard;
